import useHttp from "Hooks/useHttp";
import InputField from "components/fields/InputField";
import React, { useEffect, useState } from "react";
import routes from "routes";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Loader from "components/Loader";
const IDChangePassword = () => {
  const API = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const { PostRequest } = useHttp();
  const [username, setUsername] = useState("");
  const Data = localStorage.getItem("user");
  const [LoginUser] = useState(JSON.parse(Data));
  const [NewPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  // const [CurrentPath, setCurrentPath] = React.useState("Main Dashboard");
  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
        // setCurrentPath(routes[i].path);
      }
    }
    return activeRoute;
  };
  useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);
  const Update = async () => {
    if (LoginUser?.AccountStatus === 3) {
      toast.error("Access denied! Please contact Admin.");
      return false;
    }
    setLoading(true);
    const FormData = {
      username,
      NewPassword,
      Id: LoginUser?.id,
    };
    const { data } = await PostRequest(
      API + "users/IDChangePassword",
      FormData
    );
    setLoading(false);
    if (data.responseCode === 1) {
      toast.success(data.responseText);
    } else {
      toast.error(data.responseText);
    }
    setUsername("");
    setNewPassword("");
    setConfirmPassword("");
  };
  const CheckUserName = async () => {
    // checking User exits or not?
    setLoading(true);
    const { data } = await PostRequest(API + "users/GetUsers", {
      username,
    });
    setLoading(false);
    if (data.responseCode === 1) {
      toast.success(data.responseText);
      // change password
    } else {
      toast.error(data.responseText);
    }
  };
  return (
    <div className="flex">
      <ToastContainer />
      {/* {loading ? <Loader loading={loading} /> : null} */}
      <div className="w-99p flex-initial md:w-50p">
        <div className="mb-2 flex items-center justify-center rounded-xl bg-[#30A5FF] px-4 py-4  sm:px-6">
          <h1 className="text-2xl font-black text-white">{currentRoute}</h1>
        </div>
        <div className="rounded-xl bg-white px-4 py-3  sm:px-6">
          <InputField
            variant="auth"
            extra="mb-1"
            label="User Name *"
            placeholder="Username"
            id="Username"
            type="text"
            value={username}
            onBlur={CheckUserName}
            onChange={(e) => setUsername(e.target.value)}
          />
          <InputField
            variant="auth"
            extra="mb-1"
            label="New Password *"
            placeholder="********"
            id="name"
            type="password"
            disabled={false}
            value={NewPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <InputField
            variant="auth"
            extra="mb-1"
            label="Confirm Password *"
            placeholder="********"
            id="password"
            type="text"
            value={ConfirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            onClick={Update}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default IDChangePassword;
