import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

export default function DataTable({
  columnsData,
  tableData,
  pageSize,
  selectedFromDate,
  selectedToDate,
  setUserId,
  setType,
}) {
  const navigate = useNavigate();
  const test = localStorage.getItem("user");
  const userType = JSON.parse(test).userType;
  const [tableDataWithTotal, setTableDataWithTotal] = React.useState([]);
  const adminAddedRef = React.useRef(false);
  const [testId, setTestId] = React.useState(null)


  React.useEffect(() => {
    // Calculate totals
    const totalRow = {
      id: "",
      userId: "Total", // Or any label you prefer
      PlayPoint: tableData.reduce((acc, row) => acc + Number(row.PlayPoint), 0),
      WinPoint: tableData.reduce((acc, row) => acc + Number(row.WinPoint), 0),
      TotalEndPoint: tableData.reduce(
        (acc, row) => acc + Number(row.TotalEndPoint),
        0
      ),
      Claimpoint: tableData.reduce((acc, row) => acc + Number(row.Claimpoint), 0),
      UNClaimpoint: tableData.reduce(
        (acc, row) => acc + Number(row.UNClaimpoint),
        0
      ),
      AgentPoint: tableData.reduce((acc, row) => acc + Number(row.AgentPoint), 0),
      Net: tableData.reduce((acc, row) => acc + Number(row.Net), 0),
    };
  
    let userId = "admin"
    if (tableData[0]?.userType.toLowerCase() === "agent"){
      userId=`${testId}`
    } else if (tableData[0]?.userType.toLowerCase() === "player"){
      userId=`${testId}`
    }

    const InitialadminRow = {
      id: JSON.parse(test).id,
      userId: userId, // Or any label you prefer
      NAME:userId,
      PlayPoint: tableData.reduce((acc, row) => acc + Number(row.PlayPoint), 0),
      WinPoint: tableData.reduce((acc, row) => acc + Number(row.WinPoint), 0),
      TotalEndPoint: tableData.reduce(
        (acc, row) => acc + Number(row.TotalEndPoint),
        0
      ),
      Claimpoint: tableData.reduce((acc, row) => acc + Number(row.Claimpoint), 0),
      UNClaimpoint: tableData.reduce(
        (acc, row) => acc + Number(row.UNClaimpoint),
        0
      ),
      AgentPoint: tableData.reduce((acc, row) => acc + Number(row.AgentPoint), 0),
      Net: tableData.reduce((acc, row) => acc + Number(row.Net), 0),
      userType: "Admin",
    };
  
    // Create a new row for the admin with the same data as the total row
    const adminRow = userType.toLowerCase() === "admin" ? {
      ...totalRow,
      ...InitialadminRow// Replace "Total" with "Admin"
    } : null;
  
    // Append total row and admin row to table data
    const adminExists = tableData.some(row => row.userId === 'admin');

    if (!adminExists && userType.toLowerCase() === "admin" && !adminAddedRef.current) {
      setTableDataWithTotal([adminRow, totalRow]);
      adminAddedRef.current = true; // Set the ref to true after adding the admin row
    } else {
      setTableDataWithTotal([...tableData, totalRow]);
    }

    return () => {
      adminAddedRef.current = false; // Reset the ref when the component unmounts
    };
  }, [tableData]);

  const handleNavigation = (id, type, selectedFromDate,
    selectedToDate, userId) => {
    if (type === "Agent" || type === "STO") {
      setType(type);
      setUserId(id);
      setTestId(userId);
    } else if (type.toLowerCase() === "admin") {
      // If type is admin, set tableDataWithTotal to tableData with totalRow appended
      const totalRow = {
        id: "",
        userId: "Total", // Or any label you prefer
        PlayPoint: tableData.reduce((acc, row) => acc + Number(row.PlayPoint), 0),
        WinPoint: tableData.reduce((acc, row) => acc + Number(row.WinPoint), 0),
        TotalEndPoint: tableData.reduce(
          (acc, row) => acc + Number(row.TotalEndPoint),
          0
        ),
        Claimpoint: tableData.reduce((acc, row) => acc + Number(row.Claimpoint), 0),
        UNClaimpoint: tableData.reduce(
          (acc, row) => acc + Number(row.UNClaimpoint),
          0
        ),
        AgentPoint: tableData.reduce((acc, row) => acc + Number(row.AgentPoint), 0),
        Net: tableData.reduce((acc, row) => acc + Number(row.Net), 0),
      };
      setTableDataWithTotal([...tableData, totalRow]);
    } else if (type === "Player") {
      navigate("/admin/player-history", {
        state: {
          id,
          type,
          selectedFromDate,
          selectedToDate,
          userId,
        },
      });
    }
  };

  const updatedColumnsData = columnsData.map((column) => {
    return {
      ...column,
      cellClassName: 'vertical-line',
      headerClassName: 'vertical-line',
    };
  });

  // Add blue-text class conditionally for userId column
  updatedColumnsData.find(col => col.field === 'userId').cellClassName = (params) => {
    return 'vertical-line blue-text';
  };


  return (
    <div className="table-responsive">
      <DataGrid
        rows={tableDataWithTotal}
        columns={updatedColumnsData}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: pageSize },
          },
        }}
        onCellClick={(params, event) => {
          if (params.field === "userId") {
            handleNavigation(params.row.id, params.row.userType, selectedFromDate,
              selectedToDate, params.row.userId);
          }
        }}
      />
    </div>
  );
}