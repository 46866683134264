import React, { useCallback, useEffect, useState } from "react";
import home from "../../assets/img/topbar/Home.jpg";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const TopBar = () => {
  const navigate = useNavigate();
  const Data = localStorage.getItem("user");
  const [LoginUser] = useState(JSON.parse(Data));
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token,LoginUser]);
  const handleClick = useCallback(() => {
    localStorage.clear();
    navigate("/login");
    toast.success("Logout successful");
  }, [navigate]);
 
  return (
    <>
      <ToastContainer />
      <div
        className=" top-0 flex h-[60px] w-full items-center justify-between bg-nav "
        style={{ zIndex: 9999 }}
      >
        <div className=" ml-3 flex items-center justify-center">
          <img
            src="../assets/logo.png"
            style={{ width: 94, height: 65 }}
            alt="menu"
            className="h-6 w-6"
          />
          <span className="font-lg p-1 text-[21px] text-navlogo">
            JEETOGAMEZ
          </span>
          <span className="font-lg text-[21px] text-white">Admin</span>
        </div>

        <div className="flex items-center ">
          <div className="mr-[20px] flex h-[40px] items-center rounded-[5px] bg-[#393939] px-[10px] py-[5px] text-navlogo">
            <span>Balance: </span>
            <span className="ml-[5px]">{LoginUser?.Balance}</span>
          </div>
          <img className="mr-[40px]" src={home} alt="" />
          <button
            onClick={() => handleClick()}
            className="mr-[40px] flex h-[32px] items-center rounded-[5px] bg-[#444444] p-[10px] text-white"
          >
            logout
          </button>
        </div>
      </div>
    </>
  );
};

export default TopBar;
