// import CheckTable from "./components/CheckTable";
import React from "react";
import { columnsTransactionReport } from "./variables/columnsData";
import { useEffect, useState, useCallback } from "react";
import useHttp from "../../../Hooks/useHttp";
import { useLocation } from "react-router-dom";
import TransactionReport from "./components/TransactionReport";
import SelectField from "components/fields/SelectField";
import Pagination from "components/Pagination/Pagination";
import Loader from "components/Loader";
import InputField from "components/fields/InputField";
import routes from "routes";

const TransactionReportTables = () => {
  const [loading, setLoading] = useState(false);
  const Data = localStorage.getItem("user");
  const userData = JSON.parse(Data);
  const [username] = useState(userData.username);
  const { PostRequest } = useHttp();
  const [FromDate, setFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [ToDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const [TableData, setTableData] = useState([]);
  const location = useLocation();
  const API = process.env.REACT_APP_API_URL;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalPage, setTotalPage] = useState(0);
  const GetTransferHistory = useCallback(async () => {
    setLoading(true);
    const { data, error } = await PostRequest(
      API + "users/GetTransferHistory",
      {
        page,
        pageSize,
        startDate: FromDate,
        endDate: ToDate,
      }
    );

    setLoading(false);
    if (data.responseCode === 1) {
      setTotalPage(Math.ceil(data.responseData.count / pageSize));
      setTableData(data.responseData.rows);
    }
  }, [FromDate, ToDate, page, pageSize]);
  useEffect(() => {
    GetTransferHistory();
  }, [location.pathname, page, pageSize]);
  const handleReload = () => {
    window.location.reload();
  };

  const [currentRoute, setCurrentRoute] = useState("");
  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };

  useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  return (
    <div className="rounded-[12px] bg-white p-[10px] ">
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <div className="relative mb-5 flex items-center justify-between">
          <div className="w-full bg-blue-500 p-2 text-xl font-bold text-white">
            {currentRoute}
          </div>
        </div>
        {/* {loading ? <Loader loading={loading} /> : null} */}
        <div className="mb-3 flex gap-x-3">
          <SelectField
            extra="mb-1"
            label="Select Range"
            onChange={(e) => setPageSize(e.target.value)}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="60">60</option>
            <option value="80">80</option>
            <option value="90">90</option>
            <option value="100">100</option>
          </SelectField>
          <SelectField
            disabled={true}
            variant="auth"
            extra="mb-1"
            label="Distributor *"
          >
            <option value="">Power</option>
          </SelectField>

          <InputField
            variant="auth"
            extra="mb-1"
            label="From *"
            placeholder="Username"
            id="Username"
            value={FromDate}
            type="date"
            onChange={(e) => setFromDate(e.target.value)}
          />
          <InputField
            variant="auth"
            extra="mb-1"
            label="To *"
            placeholder="Username"
            id="Username"
            type="date"
            value={ToDate}
            onChange={(e) => setToDate(e.target.value)}
          />
          <SelectField
            disabled={true}
            variant="auth"
            extra="mb-1"
            label="Type *"
          >
            <option value="">All</option>
          </SelectField>
          <button
            type="button"
            className="mt-7 flex h-12 w-fit items-center justify-center rounded-xl border bg-indigo-700 bg-white/0 p-3 text-sm text-white outline-none"
          >
            Search
          </button>
          <button
            type="button"
            className="mt-7 flex h-12 w-fit items-center justify-center rounded-xl border bg-indigo-700 bg-white/0 p-3 text-sm text-white outline-none"
            onClick={handleReload}
          >
            Reload Page
          </button>
        </div>
        <TransactionReport
          columnsData={columnsTransactionReport}
          tableData={TableData}
          pageSize={pageSize}
        />
        {TotalPage > 0 ? (
          <Pagination totalPage={TotalPage} current={page} setPage={setPage} />
        ) : null}
      </div>
    </div>
  );
};

export default TransactionReportTables;
