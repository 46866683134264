import Footer from "components/footer/FooterAuthDefault";

import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import SignIn from "views/auth/SignIn";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Auth() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  React.useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      navigate("/admin/Stokez");
    }
  }, [token]);
  return (
    <div>
      <div className="min-w-full flex bg-[#000000]">
          
          <img style={{width:100}} src="../assets/logo.png" alt="logo" />
          <h1 className="text-[#135f9e] font-bold text-2xl mt-3">KHELOJEETO</h1> 
          <h1 className="text-[#ffffff] font-bold text-2xl mt-3 ml-1">Admin</h1>
      </div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
      
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col items-center justify-center pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <SignIn />
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
