import React from "react";
import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import useHttp from "Hooks/useHttp";

export default function SignIn() {
  const API = process.env.REACT_APP_API_URL;
  const { PostRequest } = useHttp();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const LoginAdmin = async () => {
    const { data } = await PostRequest(API + "users/AdminLogin", {
      username,
      password,
    });
    if (data.responseCode === 0) {
      toast.error("Please Check Email and Password");
    } else {
      toast.success("Login successfully");
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("user", JSON.stringify(data.data.user));
      navigate("/admin/dashboard");
    }
  };

  return (
    <div className="w-full max-w-md mx-auto mt-10">
      <ToastContainer />
      <div className="p-6 bg-white rounded-lg shadow-md">        
        <h2 className="text-2xl font-bold mb-6 text-center">Log in</h2>
        <form>
          <div className="mb-4">
            <label htmlFor="username" className="sr-only">Username</label>
            <InputField
              variant="auth"
              extra="mb-3"
              placeholder="Username"
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border rounded border-gray-300 focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="sr-only">Password</label>
            <InputField
              variant="auth"
              extra="mb-3"
              placeholder="Password"
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded border-gray-300 focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="mb-4 flex items-center justify-between">
            <div className="flex items-center">
              <Checkbox />
              <p className="ml-2 text-sm text-gray-600">Remember Me</p>
            </div>
            {/* <a
              className="text-sm text-blue-500 hover:underline"
              href="#"
            >
              Forgot Password?
            </a> */}
          </div>
          <div>
            <button
              type="button"
              onClick={LoginAdmin}
              className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
