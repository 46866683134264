import useHttp from "Hooks/useHttp";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import React, { useEffect, useState } from "react";
import routes from "routes";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Loader from "components/Loader";
const AdjustPoint = () => {
  const Data = localStorage.getItem("user");
  const LoginUser = JSON.parse(Data);
  const location = useLocation();
  const API = process.env.REACT_APP_API_URL;
  const { PostRequest } = useHttp();
  const [username, setUsername] = useState("");
  const [Balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [option,setOption] = useState([]);
  const [AmountToTransfer, setAmountToTransfer] = useState("");
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);
  const Update = async () => {
    if (LoginUser?.AccountStatus === 3) {
      toast.error("Access denied! Please contact Admin.");
      return false;
    }
    if (Balance <= AmountToTransfer) {
      toast.error("Low Balance! Please Recharge Your wallet ");
      return false;
    }
    setLoading(true);
    const FormData = {
      username,
      Balance: AmountToTransfer,
      TransferBy: LoginUser?.username,
    };
    const { data } = await PostRequest(API + "users/DeductBalance", FormData);
    setLoading(false);
    if (data.responseCode === 1) {
      toast.success(data.responseText);
    } else {
      toast.error(data.responseText);
    }
  };

  useEffect(()=>{
    if(LoginUser?.userType === "Admin"){
      setOption(["STO","Agent","Player"])
    }else if(LoginUser?.userType === "STO"){
      setOption(["Agent","Player"])
    }else if(LoginUser?.userType === "Agent") {
      setOption(["Player"])
    }
  },[LoginUser])
  const CheckUserName = async () => {
    setBalance(0);
    setLoading(true);
    const { data } = await PostRequest(API + "users/GetUsers", {
      username,
    });
    setLoading(false);
    if (data.responseCode === 1) {
      setBalance(data.responseData.Balance);
      toast.success(data.responseText);
    } else {
      toast.error(data.responseText);
    }
  };
  return (
    <div className="flex">
      <ToastContainer />
      {/* {loading ? <Loader loading={loading} /> : null} */}
      <div className="w-99p flex-initial md:w-50p">
        <div className="mb-2 flex items-center justify-center rounded-xl bg-[#30A5FF] px-4 py-4  sm:px-6">
          <h1 className="text-2xl font-black text-white">{currentRoute}</h1>
        </div>
        <div className="rounded-xl bg-white px-4 py-3  sm:px-6">
          <SelectField
            variant="auth"
            extra="mb-1"
            label={`Select User Type *`}
            id="email"
            type="text"
          >
            {option.map((name)=>{
              return <option key={name}>{name}</option>
            })}
          </SelectField>
          <InputField
            variant="auth"
            extra="mb-1"
            label="User Name *"
            placeholder="Username"
            id="Username"
            type="text"
            value={username}
            onBlur={CheckUserName}
            onChange={(e) => setUsername(e.target.value)}
          />
          <InputField
            variant="auth"
            extra="mb-1"
            label="Balance *"
            placeholder="Name"
            id="name"
            type="text"
            disabled={true}
            value={Balance}
            onChange={(e) => setBalance(e.target.value)}
          />
          <InputField
            variant="auth"
            extra="mb-1"
            label="Amount To Adjust *"
            placeholder="51000"
            id="password"
            type="text"
            value={AmountToTransfer}
            onChange={(e) => setAmountToTransfer(e.target.value)}
          />
        </div>
        <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            onClick={Update}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdjustPoint;
