import useHttp from "Hooks/useHttp";
import InputField from "components/fields/InputField";
import React, { useCallback, useEffect, useState } from "react";
import routes from "routes";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import SelectField from "components/fields/SelectField";
const SetPower = () => {
  const [loading, setLoading] = useState(false);
  const API = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const { PostRequest } = useHttp();
  const [GameId, setGameId] = useState("c9aaa5f3");
  const [power, setPower] = useState("1");
  const [currentRoute, setCurrentRoute] = useState("Main Dashboard");


  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].layout + "/" + routes[i].path) !== -1) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };

  useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const CurrentGame = useCallback(async () => {
    setLoading(true);
    const Data = localStorage.getItem("user");
    const LoginUser = JSON.parse(Data);
    const FormData = { username: LoginUser?.username };


    const { data } = await PostRequest(API + "games/CurrentGame", FormData, { Authorization: `Bearer ${localStorage.getItem("token")}` });
    setLoading(false);

    if (data && data.data && data.data.game_id) {
      setGameId(data.data.game_id);
      setPower(data.data.power);
    }
  }, [PostRequest, API]);

  useEffect(() => {
    CurrentGame();
  }, []);

  const Update = async () => {
    setLoading(true);
    const FormData = { game_id: GameId, power };

    const { data } = await PostRequest(API + "games/SetPower", FormData);
    setLoading(false);

    if (data.status === "error") {
      toast.error(data.msg);
    } else {
      toast.success(data.msg);
    }
  };
  return (
    <div className="flex">
      {/* {loading ? <Loader loading={loading} /> : null} */}
      <ToastContainer />
      <div className="w-99p flex-initial md:w-50p">
        <div className="mb-2 flex items-center justify-center rounded-xl bg-[#30A5FF] px-4 py-4  sm:px-6">
          <h1 className="text-2xl font-black text-white">{currentRoute}</h1>
        </div>
        <div className="rounded-xl bg-white px-4 py-3  sm:px-6">
          <InputField
            variant="auth"
            extra="mb-1"
            label="Current Game Id *"
            id="name"
            type="text"
            disabled={true}
            value={GameId}
            onChange={(e) => setGameId(e.target.value)}
          />
          <SelectField
            variant="auth"
            extra="mb-1"
            label={`Select Power *`}
            id="email"
            type="text"
            value={power}
            onChange={(e) => setPower(e.target.value)}
          >
            <option value="1">1x</option>
            <option value="2">2x</option>
            <option value="3">3x</option>
            <option value="4">4x</option>
            <option value="5">5x</option>
            <option value="6">6x</option>
            <option value="7">7x</option>
            <option value="8">8x</option>
            <option value="9">9x</option>
            <option value="10">10x</option>
          </SelectField>
        </div>
        <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            onClick={Update}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetPower;
